<template>
  <div class="role main-cnt">
    <div class="title">角色管理</div>
    <div class="content">
      <common-table
        ref="roleTable"
        tableHeight="calc(100vh - 325px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="BasicApi.roleList"
        :filters="filters"
        :columns="tableColumns"
        @edit="roleEdit"
        @author="authorEdit"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="showAddDialog"
            v-if="authData.indexOf('r_42mg3EsbSfMC9npBlTFqkaiYDVO7') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增角色</el-button
          >
        </template>
      </common-table>
    </div>

    <!-- 新增/编辑角色弹框 -->
    <w-dialog
      ref="addDialog"
      class="add-dialog"
      :title="isEdit ? '编辑角色' : '新增角色'"
      width="38%"
      btnWidth="140px"
      top="20vh"
      :confirmText="isEdit ? '确认编辑' : '确认新增'"
      @wConfirm="addConfirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="角色名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
        <el-form-item class="block" label="备注信息" prop="remark">
          <el-input
            v-model="ruleForm.remark"
            :rows="5"
            type="textarea"
            placeholder="请输入备注信息"
          />
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 权限 弹框 -->
    <w-dialog
      ref="authDialog"
      class="auth-dialog"
      title="权限"
      width="60%"
      btnWidth="140px"
      top="10vh"
      confirmText="提交保存"
      @wConfirm="authConfirm"
    >
      <div class="mcontent" v-loading="authLoading">
        <div class="name">选择权限模块</div>
        <div class="select">
          <el-select
            v-model="cmodule"
            placeholder="请选择权限模块"
            @change="moduleChange"
          >
            <el-option
              :label="item.nm_name"
              :value="item.nm_id"
              v-for="item in authModules"
              :key="item.nm_id"
            ></el-option>
          </el-select>
        </div>
        <div class="checkbox-wp" v-if="authTree[0]">
          <el-checkbox
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
            >全选/反选</el-checkbox
          >
          <div v-for="item in authTree[0].child" :key="item.n_id">
            <el-checkbox
              v-model="item.checkMenuAll"
              :indeterminate="item.isIndeterminate"
              @change="handleCheckMenuAllChange($event, item)"
              >{{ item.n_name + "（全选）" }}</el-checkbox
            >
            <el-checkbox-group
              v-model="item.checkedAuth"
              @change="handleCheckedAuthChange($event, item)"
            >
              <el-checkbox
                v-for="itm in item.child"
                :key="itm.n_id"
                :label="itm.n_id"
                >{{ itm.n_name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </w-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { BasicApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 表格对象 */
const roleTable = ref(null);
/** 筛选条件列表 */
const filters = ref([
  {
    name: "keyword",
    filterType: "search",
    value: "",
    placeholder: "输入关键字搜索",
  },
]);

/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "nr_name",
    label: "角色",
    minWidth: 120,
    color: "--text-color",
  },
  // {
  //   prop: "node_text",
  //   label: "权限",
  //   minWidth: 130,
  //   color: "--text-color",
  //   showTooltip: true,
  // },
  // {
  //   prop: "user_text",
  //   label: "成员",
  //   minWidth: 120,
  //   color: "--text-third-color",
  // },
  {
    prop: "cuser",
    prop2: "u_name",
    label: "创建人",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "nr_ctime",
    label: "创建时间",
    minWidth: 160,
    color: "--text-third-color",
  },
  {
    prop: "nr_desc",
    label: "备注",
    minWidth: 160,
    color: "--text-third-color",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 200,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "r_wSAPoQp3ZiXvmqU2O5Vz8tc1CMhg",
      },
      {
        name: "权限",
        action: "author",
        token: "r_5k7eMxiFUQs1H3fyp0RgG9qntr8j",
      },
    ],
  },
]);
/** 当前操作 行数据 */
const currentRow = ref(null);
/** 表格编辑 */
const roleEdit = (row) => {
  currentRow.value = row;
  isEdit.value = true;
  ruleForm.name = row.nr_name;
  ruleForm.remark = row.nr_desc;
  addDialog.value.show();
};
/** 表格 权限设置 */
const authorEdit = (row) => {
  currentRow.value = row;
  cmodule.value = "";
  authTree.value = [];
  authModules.value = [];
  authDialog.value.show();
  getRoleInfo();
  getAuthModule();
};

/** 新增/编辑弹框 */
const addDialog = ref(null);
/** 当前是否是编辑操作 */
const isEdit = ref(false);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  name: "", // 角色名称
  remark: "", // 备注信息
});
/** 表单规则对象 */
const rules = reactive({
  name: [
    {
      required: true,
      message: "请输入角色名称",
      trigger: "blur",
    },
  ],
  remark: [
    {
      required: true,
      message: "请输入备注信息",
      trigger: "blur",
    },
  ],
});
/** 弹出 新增/编辑房型 弹框 */
const showAddDialog = () => {
  isEdit.value = false;
  // 表单验证重置
  if (addForm.value) {
    addForm.value.resetFields();
  }
  ruleForm.name = "";
  ruleForm.remark = "";
  addDialog.value.show();
};
/** 角色新增/编辑 确认 */
const addConfirm = () => {
  addForm.value.validate((valid) => {
    if (valid) {
      addDialog.value.isLoading = true;
      let data = {
        nr_name: ruleForm.name,
        nr_desc: ruleForm.remark,
      };
      let url = "roleAdd";
      if (isEdit.value) {
        data.nr_id = currentRow.value.nr_id;
        url = "roleUpdate";
      }
      BasicApi[url](data).then((res) => {
        addDialog.value.isLoading = false;
        if (res.Code === 200) {
          ElMessage.success("新增角色成功！");
          addDialog.value.close();
          // 重新获取角色列表
          roleTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : "新增角色失败！";
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 权限弹框 */
const authDialog = ref(null);
/** 权限模块列表 */
const authModules = ref([]);
/** 选择的权限模块 */
const cmodule = ref("");
/** 是否正在加载权限数据 */
const authLoading = ref(false);
/** 权限列表 */
const authTree = ref([]);
/** 获取权限模块 */
const getAuthModule = () => {
  BasicApi.getModule().then((res) => {
    if (res.Code === 200) {
      authModules.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取权限模板失败！";
      ElMessage.error(msg);
    }
  });
};
/** 获取模块下权限 */
const getNodes = () => {
  authLoading.value = true;
  BasicApi.getNode({ nm_id: cmodule.value }).then((res) => {
    authLoading.value = false;
    if (res.Code === 200) {
      if (res.Data[0] && res.Data[0].child && res.Data[0].child.length > 0) {
        if (currentRow.value.nid_arr && currentRow.value.nid_arr.length > 0) {
          let checkedArr = [];
          res.Data[0].child.forEach((item) => {
            if (item.child && item.child.length > 0) {
              let flag = item.child.every((itm) =>
                currentRow.value.nid_arr.includes(itm.n_id)
              );
              let flag2 = item.child.every(
                (itm) => !currentRow.value.nid_arr.includes(itm.n_id)
              );
              if (flag) {
                item.isIndeterminate = false;
                item.checkMenuAll = true;
                item.checkedAuth = item.child.map((itm) => itm.n_id);
                checkedArr.push(item.n_id);
              } else if (flag2) {
                item.isIndeterminate = false;
                item.checkMenuAll = false;
                item.checkedAuth = [];
              } else {
                item.isIndeterminate = true;
                item.checkMenuAll = false;
                let arr = [];
                item.child.forEach((itm) => {
                  if (currentRow.value.nid_arr.includes(itm.n_id)) {
                    arr.push(itm.n_id);
                  }
                });
                item.checkedAuth = arr;
              }
            } else {
              item.isIndeterminate = false;
              item.checkMenuAll = false;
              item.checkedAuth = [];
            }
          });
          if (
            checkedArr.length > 0 &&
            checkedArr.length === res.Data[0].child.length
          ) {
            checkAll.value = true;
            isIndeterminate.value = false;
          } else if (res.Data[0].child.every((itm) => !itm.isIndeterminate)) {
            checkAll.value = false;
            isIndeterminate.value = false;
          } else {
            checkAll.value = false;
            isIndeterminate.value = true;
          }
        } else {
          checkAll.value = false;
          isIndeterminate.value = false;
          res.Data[0].child.forEach((item) => {
            item.isIndeterminate = false;
            item.checkMenuAll = false;
            item.checkedAuth = [];
          });
        }
      } else {
        checkAll.value = false;
        isIndeterminate.value = false;
      }
      authTree.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取权限数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 模块切换处理 */
const moduleChange = () => {
  checkAll.value = false;
  isIndeterminate.value = false;
  getNodes();
};
/** 模块是否全选 */
const checkAll = ref(false);
/** 全选框勾选状态 */
const isIndeterminate = ref(false);
/** 模块全选状态改变处理 */
const handleCheckAllChange = (val) => {
  if (val) {
    authTree.value[0].child.forEach((item) => {
      item.checkMenuAll = true;
      item.isIndeterminate = false;
      if (item.child && item.child.length > 0) {
        item.checkedAuth = item.child.map((itm) => itm.n_id);
      } else {
        item.checkedAuth = [];
      }
    });
  } else {
    authTree.value[0].child.forEach((item) => {
      item.checkMenuAll = false;
      item.isIndeterminate = false;
      item.checkedAuth = [];
    });
  }
  isIndeterminate.value = false;
};
/** 菜单全选状态改变 */
const handleCheckMenuAllChange = (val, obj) => {
  obj.checkedAuth =
    val && obj.child && obj.child.length > 0
      ? obj.child.map((item) => item.n_id)
      : [];
  obj.isIndeterminate = false;
  if (val) {
    let flag = authTree.value[0].child.every((itm) => itm.checkMenuAll);
    if (flag) {
      checkAll.value = true;
    } else {
      checkAll.value = false;
    }
  } else {
    checkAll.value = false;
  }
  let arr = [];
  authTree.value[0].child.forEach((item) => {
    if (item.checkMenuAll) {
      arr.push(item);
    }
  });
  isIndeterminate.value =
    arr.length > 0 && arr.length < authTree.value[0].child.length;
};
/** 权限选择改变处理 */
const handleCheckedAuthChange = (value, obj) => {
  const checkedCount = value.length;
  obj.checkMenuAll = checkedCount === obj.child.length;
  obj.isIndeterminate = checkedCount > 0 && checkedCount < obj.child.length;
  if (obj.checkMenuAll) {
    let flag = authTree.value[0].child.every((itm) => itm.checkMenuAll);
    if (flag) {
      checkAll.value = true;
    } else {
      checkAll.value = false;
    }
  } else {
    checkAll.value = false;
  }
  let arr = [];
  authTree.value[0].child.forEach((item) => {
    if (item.checkMenuAll) {
      arr.push(item);
    }
  });
  isIndeterminate.value =
    arr.length > 0 && arr.length < authTree.value[0].child.length;
};
/** 权限修改确认 */
const authConfirm = () => {
  if (!cmodule.value || !authTree.value[0]) {
    ElMessage.warning("请选择权限模块！");
    return;
  }
  authDialog.value.isLoading = true;
  let arr = [];
  authTree.value[0].child.forEach((item) => {
    arr = arr.concat(item.checkedAuth);
  });
  let data = {
    nm_id: cmodule.value,
    nr_id: currentRow.value.nr_id,
    node: arr.join(","),
  };
  BasicApi.roleUpdateRole(data).then((res) => {
    authDialog.value.isLoading = false;
    if (res.Code === 200) {
      ElMessage.success("权限修改成功！");
      // authDialog.value.close();
      // 重新获取表格数据
      getRoleInfo()
      roleTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "权限修改失败！";
      ElMessage.error(msg);
    }
  });
};

/** 获取角色详情数据 */
const getRoleInfo = () => {
  BasicApi.roleInfo({ nr_id: currentRow.value.nr_id }).then((res) => {
    if (res.Code === 200) {
      currentRow.value = res.Data ? res.Data : {};
    } else {
      let msg = res.Message ? res.Message : "获取角色详情数据失败！";
      ElMessage.error(msg);
    }
  });
};

onMounted(() => {
  roleTable.value.tableLoad();
});
</script>

<style lang="scss">
.role {
  font-family: "Source Han Sans CN";
  .content {
    padding: 20px;
  }
  .add-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 0 15px 60px;
        .add-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 20px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-select {
                width: 100%;
                .el-input {
                  width: 100%;
                }
              }
            }
          }
          .el-form-item.desc {
            width: 66.7%;
          }
          .el-form-item.block {
            width: 100%;
          }
          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }

  .auth-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 0;
        .mcontent {
          padding: 25px 25px 50px;
          min-height: 600px;
          .name {
            font-size: 15px;
            color: var(--text-second-color);
            margin-bottom: 15px;
          }
          .select {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
</style>
